import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../_metronic/_partials/controls";
import { Form, Button, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import styled from 'styled-components';
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import PasswordStrengthBar from 'react-password-strength-bar';
import * as authAction from "../redux/authAction";

const CONTAINER = styled.div`
.error {
  border: 1px solid #FF6565;
}`;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Field is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])^.{8,}$/, 
      'Password should be at least 8 characters and has to contain a mixture of letters, numbers and at least one special character'
    ),
  confirmPassword: Yup.string()
    .required('Field is required')
    .oneOf([Yup.ref('password'), null], `Password didn't match`)
})

export function SetupPasswordPage(props){
  const dispatch = useDispatch();
  const authReducer = useSelector(state => state.auth);
  const { register, handleSubmit, setError, errors, watch } = useForm({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    let tokenSetupPassword = props.location.search.substring(7);
    if(tokenSetupPassword !== ""){
      dispatch(authAction.setTokenSetupPassword(tokenSetupPassword));
    } else {
      dispatch(authAction.setTokenSetupPassword(null));
    }
  }, [])

  useEffect(() => {
    if(authReducer.isLoading === false && authReducer.isError === false && authReducer.setupPassword !== null){
      dispatch(authAction.setTokenSetupPassword(null));
      props.history.push('/auth/login');
    } else if(authReducer.isLoading === false && authReducer.isError === true){
      setError("password", {
        type: "manual",
        message: authReducer.errorMsg.message
      });
    }
  }, [authReducer])

  function onSubmit(values){
    dispatch(authAction.setupPasswordRequest({password: values.password}));
  }

  return(
    <div className="d-flex align-item-center justify-content-center" 
      style={{
        height: '100vh', 
        alignItems: 'center', 
        justifyContent: 'center', 
      }}>
      <div 
        style={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          backgroundImage: `url(${toAbsoluteUrl("/media/nkf/volunteer-together.jpg")})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          filter: 'blur(8px)',
          position: 'absolute'
        }}
      />
      <Card style={{width: '50rem', height: '32rem'}}>
        <CardHeader>
          <div className="col-lg-12 justify-content-center align-items-center d-flex">
            <div className="col-lg-2">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/nkf-circle-hearts-logo.png")}
                style={{
                  maxHeight: '50px'
                }}
              />
            </div>
            <div className="col-lg-8">
              <h5 className="text-center ">Setup Password</h5>
            </div>
            <div className="col-lg-2">
              <SVG
                src={toAbsoluteUrl(
                  "/media/nkf/logo-nkf.svg"
                )}
                className="h-50 align-self-center"
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <CONTAINER>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="d-flex col-lg-12 mb-0 mt-0">
                <Form.Group className="col-lg-8 mx-auto mb-0 mt-0">
                  <Form.Label className={"font-weight-bold"}>
                    Password
                  </Form.Label>
                  {
                    <Form.Label className={`${errors?.password ? 'text-danger' : 'text-muted'} d-flex`}>{errors?.password?.message || 'Password should be at least 8 characters and has to contain a mixture of letters, numbers and at least one special character'}</Form.Label>
                  }
                  <Form.Control 
                    type="password"
                    name="password"
                    ref={register}
                    className={(errors.password) && "error"}
                  />
                  <PasswordStrengthBar 
                    password={watch('password')} 
                    minLength={8}
                  />
                </Form.Group>
              </Form.Group>
              <Form.Group className="d-flex col-lg-12 mb-0 mt-0">
                <Form.Group className="col-lg-8 mx-auto mb-0 mt-0">
                  <Form.Label className={"font-weight-bold"}>
                    Confirm Password
                  </Form.Label>
                  {
                    errors?.confirmPassword &&
                    <Form.Label className="text-danger d-flex">{errors?.confirmPassword?.message}</Form.Label>
                  }
                  <Form.Control 
                    type="password"
                    name="confirmPassword"
                    ref={register}
                    className={(errors.confirmPassword) && "error"}
                  />
                </Form.Group>
              </Form.Group>
              <Form.Group className={`col-lg-6 mx-auto d-flex justify-context-center align-items-center flex-column 
                ${errors.access_code ? 'mt-0' : 'mt-5'}`}>
                <Button className={'col-lg-6 col-xs-6 mx-auto'} variant="primary" disabled={authReducer.isLoading} type="submit">
                  Submit
                  {
                    authReducer.isLoading &&
                    <span className="ml-3 spinner spinner-white"></span>
                  }
                </Button>
              </Form.Group>
            </Form>
          </CONTAINER>
        </CardBody>
      </Card>
    </div>
  )
}