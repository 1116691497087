import React, { useEffect, useState } from "react";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { BasicInfoForm } from "../form/BasicInfoForm";
import { AdditionalInfoForm } from "../form/AdditionalInfoForm";
import { RequirementVolunteerForm } from "../form/RequirementVolunteerForm";
import { ClientParticularForm } from "../form/ClientParticularForm";
import { RemarkForm } from "../form/RemarkForm";
import { ImportanInfoForm } from "../form/ImportanInfoForm";
import { ClientResidentialAreaForm } from "../form/ClientResidentialAreaForm";
import * as vrAction from "../redux/volunteerRequestAction";
import { LoadingCard } from "../../../component/LoadingCard";
import { ConfirmModalVr } from "../modal/ConfirmModalVr";
import { ModalInfoVr } from "../modal/ModalInfoVr";

export function VolunteerRequestDetailPage(props) {
  const dispatch = useDispatch();
  const vrReducer = useSelector((state) => state.vr);
  const dataVr = useSelector(
    (state) => state.vr.vrDetail?.data?.volunteerRequest
  );
  const dataAssignee = useSelector(
    (state) => state.vr.vrDetail?.data?.assignedVolunteer
  );
  const [idVr, setIdVr] = useState(null);
  const [modalConfirm, setModalConfirm] = useState({
    isVisible: false,
    message: "",
    onConfirm: () => null,
  });
  const [modalInfo, setModalInfo] = useState({
    type: "",
    show: false,
  });

  useEffect(() => {
    let _IdVr = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
    setIdVr(_IdVr);
    dispatch(vrAction.vrDetailRequest({ Id: _IdVr }));

    return () => {
      dispatch(vrAction.vrDetailReset());
    };
  }, []);

  useEffect(() => {
    if (
      vrReducer.isLoading === false &&
      (vrReducer.vrRegister !== null || vrReducer.vrCancel !== null)
    ) {
      if (vrReducer.vrCancel !== null) {
        setModalInfo({
          ...modalInfo,
          type: "cancel",
          show: true,
        });
      } else if (vrReducer.vrRegister !== null) {
        setModalInfo({
          ...modalInfo,
          type: "register",
          show: true,
        });
      }
      dispatch(vrAction.vrRegisterReset());
      dispatch(vrAction.vrCancelReset());
      dispatch(vrAction.vrDetailRequest({ Id: idVr }));
    }
  }, [vrReducer]);

  function responseAction() {
    if (Object.keys(dataAssignee).length === 0) {
      setModalConfirm({
        ...modalConfirm,
        isVisible: true,
        message: `We have received your registration to volunteer for this activity/event. We will get back to you for confirmation of acceptance. 
          \n Click Confirm to continue or Cancel to revert.`,
        onConfirm: () => onRegisterConfirm(),
      });
    } else {
      if (
        dataAssignee?.Assignee_Status__c === "Registered" ||
        dataAssignee?.Assignee_Status__c === "Accepted"
      ) {
        setModalConfirm({
          ...modalConfirm,
          isVisible: true,
          message: `We have received your ${
            dataVr?.Request_status__c === "Started" ? "Withdraw" : "Cancel"
          } to volunteer for this activity/event.
              \n Click Confirm to continue or Cancel to revert.`,
          onConfirm: () => onCancelConfirm(),
        });
      } else if (
        dataAssignee?.Assignee_Status__c === "Selected" ||
        dataAssignee?.Assignee_Status__c === "Contacted" ||
        dataAssignee?.Assignee_Status__c === "Cancelled" ||
        dataAssignee?.Assignee_Status__c === "Withdrawn"
      ) {
        setModalConfirm({
          ...modalConfirm,
          isVisible: true,
          message: `We have received your registration to volunteer for this activity/event. We will get back to you for confirmation of acceptance. 
              \n Click Confirm to continue or Cancel to revert.`,
          onConfirm: () => onRegisterConfirm(),
        });
      }
    }
  }

  function onRegisterConfirm() {
    dispatch(vrAction.vrRegisterRequest({ Id: idVr }));
  }

  function onCancelConfirm() {
    dispatch(vrAction.vrCancelRequest({ Id: idVr }));
  }

  return (
    <>
      <Card>
        {dataVr ? (
          <CardBody>
            {dataVr?.Request_status__c === "Planned" ||
            dataVr?.Request_status__c === "Started" ? (
              <div
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  display: "flex",
                  alignSelf: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant={
                    Object.keys(dataAssignee).length === 0
                      ? "success"
                      : dataAssignee?.Assignee_Status__c === "Selected" ||
                        dataAssignee?.Assignee_Status__c === "Contacted" ||
                        dataAssignee?.Assignee_Status__c === "Cancelled" ||
                        dataAssignee?.Assignee_Status__c === "Withdrawn"
                      ? "success"
                      : "secondary"
                  }
                  style={{ width: "14%", marginRight: 10 }}
                  onClick={() => responseAction()}
                  disabled={vrReducer.isLoading}
                >
                  {Object.keys(dataAssignee).length === 0
                    ? "Register"
                    : dataAssignee?.Assignee_Status__c === "Selected" ||
                      dataAssignee?.Assignee_Status__c === "Contacted" ||
                      dataAssignee?.Assignee_Status__c === "Cancelled" ||
                      dataAssignee?.Assignee_Status__c === "Withdrawn"
                    ? "Register"
                    : dataVr?.Request_status__c === "Started"
                    ? "Withdraw"
                    : "Cancel"}
                  {vrReducer.isLoading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </Button>
                {/* {
                  isAccept &&
                  <Button variant="primary" style={{ width: '14%', marginRight: 10 }}>Check In</Button>
                }
                {
                  isAccept &&
                  <Button variant="warning" style={{ width: '14%' }}>Check Out</Button>
                } */}
              </div>
            ) : null}
            <ul className="nav nav-tabs nav-tabs-line " role="tablist">
              <li className="nav-item" onClick={() => null}>
                <a
                  className={`nav-link basic active`}
                  data-toggle="tab"
                  role="tab"
                  href="#basic-info"
                >
                  Basic Information
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <BasicInfoForm />
            </div>
            <ul className="nav nav-tabs nav-tabs-line " role="tablist">
              <li className="nav-item" onClick={() => null}>
                <a
                  className={`nav-link basic active`}
                  data-toggle="tab"
                  role="tab"
                  href="#additional-info"
                >
                  Additional Information
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <AdditionalInfoForm />
            </div>
            <ul className="nav nav-tabs nav-tabs-line " role="tablist">
              <li className="nav-item" onClick={() => null}>
                <a
                  className={`nav-link basic active`}
                  data-toggle="tab"
                  role="tab"
                  href="#requirement-info"
                >
                  Volunteer Requirements
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <RequirementVolunteerForm />
            </div>
            <ul className="nav nav-tabs nav-tabs-line " role="tablist">
              <li className="nav-item" onClick={() => null}>
                <a
                  className={`nav-link basic active`}
                  data-toggle="tab"
                  role="tab"
                  href="#requirement-info"
                >
                  Client's Particulars
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <ClientParticularForm />
            </div>
            <ul className="nav nav-tabs nav-tabs-line " role="tablist">
              <li className="nav-item" onClick={() => null}>
                <a
                  className={`nav-link basic active`}
                  data-toggle="tab"
                  role="tab"
                  href="#requirement-info"
                >
                  Remarks
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <RemarkForm />
            </div>
            <ul className="nav nav-tabs nav-tabs-line " role="tablist">
              <li className="nav-item" onClick={() => null}>
                <a
                  className={`nav-link basic active`}
                  data-toggle="tab"
                  role="tab"
                  href="#requirement-info"
                >
                  Client Residential Address
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <ClientResidentialAreaForm />
            </div>
            <ul className="nav nav-tabs nav-tabs-line " role="tablist">
              <li className="nav-item" onClick={() => null}>
                <a
                  className={`nav-link basic active`}
                  data-toggle="tab"
                  role="tab"
                  href="#requirement-info"
                >
                  Important Information
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <ImportanInfoForm />
            </div>
            <ConfirmModalVr
              show={modalConfirm.isVisible}
              message={modalConfirm.message}
              onHide={() =>
                setModalConfirm({ ...modalConfirm, isVisible: false })
              }
              onConfirm={() => {
                setModalConfirm({ ...modalConfirm, isVisible: false });
                modalConfirm.onConfirm();
              }}
            />
            <ModalInfoVr
              show={modalInfo.show}
              type={modalInfo.type}
              onHide={() => setModalInfo({ ...modalInfo, show: false })}
            />
          </CardBody>
        ) : (
          <LoadingCard />
        )}
      </Card>
    </>
  );
}
